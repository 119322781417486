export const setFilter = (filterData, params, findIndex) => {
  let _filterData = filterData;
  //let inUseItem;
  params &&
    params.length > 0 &&
    params.map((paramItem) => {
      const {typeOfFilter, index, checked, title} = paramItem;

      // setting the selected state of filter UI
      if (typeOfFilter) {
        if (typeOfFilter === 'tiles') {
          _filterData[typeOfFilter][index].checked = !_filterData[typeOfFilter][index].checked;
        } else if (typeOfFilter === 'RADIO') {
          let _sectionData = [];
          _filterData.filtersList[0].filterTypeData.sectionData.map((singleItem) => {
            if (singleItem && findIndex(params, ['paramForAPI', singleItem.paramForAPI]) !== -1) {
              _sectionData.push({
                ...singleItem,
                checked: true,
              });
            } else {
              _sectionData.push({
                ...singleItem,
                checked: false,
              });
            }
          });
          _filterData.filtersList[0].filterTypeData.sectionData = _sectionData;
        } else if (typeOfFilter === 'CHECKBOX') {
          if (title === 'In-Use' && checked) {
            //inUseItem = paramItem;
          }

          _filterData.filtersList[1].filterTypeData.sectionData[paramItem.index] = paramItem;
        }
      }
      // setting the selected state of filter UI > ends here
    });
};

export const setCroppedImage = (
  replacedImage,
  setImageId,
  setShowReplaceModal,
  imgId,
  setCropDialogue,
) => {
  if (
    //actionName === "IMAGE_REPLACEMENT" &&
    replacedImage &&
    replacedImage.imageDetail &&
    replacedImage.imageDetail.imageId
  ) {
    setImageId(replacedImage.imageDetail.imageId);
    setShowReplaceModal(true);
  }

  if (imgId && imgId.length && imgId.length > 0) {
    setImageId(imgId);
    setCropDialogue(true);
  }
};

export const fetchListOnSearch = (props) => {
  const {
    fetchImageList,
    search,
    filterData,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  } = props;
  // console.log('>>>> >>>> fetchListOnSearch', props);
  if (search.length === 0) {
    fetchImageList({
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
    });
  }
  if (search?.length >= 2) {
    fetchImageList({
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
    });
  }
};

// export const fetchCourseData = (courseIds, fetchCourseDetails, setCourseDetails) =>
// {
//   if (courseIds?.length > 0) {
//     fetchCourseDetails();
//   }
//   if (courseIds?.length === 0) {
//     setCourseDetails([]);
//   }
// }

export const setCategory = (checkBoxInUseData, checkBoxNotInUseData, filtersList) => {
  const isInUseChecked = checkBoxInUseData && checkBoxInUseData.checked;
  const isNotInUseChecked = checkBoxNotInUseData && checkBoxNotInUseData.checked;

  let sortType = '';
  const radioElement = filtersList[0].filterTypeData.sectionData?.find((ele) => ele?.checked);

  if (radioElement !== undefined) {
    sortType = radioElement?.paramForAPI;
  }
  let apiParamUsedInCourse = false;
  let apiParamNotUsedInCourse = false;
  if (isInUseChecked) {
    apiParamUsedInCourse = true;
  }
  if (isNotInUseChecked) {
    apiParamNotUsedInCourse = false;
  }
  if (isInUseChecked && isNotInUseChecked) {
    apiParamUsedInCourse = false;
    apiParamNotUsedInCourse = true;
  }
  if (!isInUseChecked && !isNotInUseChecked) {
    apiParamUsedInCourse = false;
    apiParamNotUsedInCourse = true;
  }

  return [sortType, apiParamUsedInCourse, apiParamNotUsedInCourse];
};

export const searchAndSort = (
  dataSearchAndSort,
  setImageList,
  setImageListAll,
  // callbackFunction,
  setSearchFilterEmptyState,
  setErrorStateTemplate,
  isOnline,
) => {
  if (dataSearchAndSort != 'ERR_NETWORK' && dataSearchAndSort) {
    //setSearchImageList(dataSearchAndSort?.data);
    setImageList(dataSearchAndSort?.data);
    setImageListAll(dataSearchAndSort?.data);
    sessionStorage.setItem('imagelistStorage', JSON.stringify(dataSearchAndSort?.data));
    //callbackFunction && callbackFunction();
    setSearchFilterEmptyState(true);
  } else if (dataSearchAndSort == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
    sessionStorage.setItem('imagelistStorage', JSON.stringify([]));
  }
};

export const setImageDetailsData = (
  dataImageDetail,
  isImageDetailDrawerUpdated,
  setNotificationData,
  imageinput,
  setImageDetailDrawerUpdated,
  setImageDetail,
  setImageInput,
  setDescriptionInput,
  setCourseIds,
  setMainImageBackup,
) => {
  if (dataImageDetail) {
    if (dataImageDetail.status?.toLowerCase() === 'success') {
      if (isImageDetailDrawerUpdated) {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Saved Changes',
          description: `Details for the ${imageinput} were saved.`,
        });
        setImageDetailDrawerUpdated(false);
      }
      setImageDetailDrawerUpdated(false);
      setImageDetail(dataImageDetail?.data);
      setImageInput(dataImageDetail?.data?.fileName);
      setDescriptionInput(dataImageDetail?.data?.description);
      setCourseIds(dataImageDetail?.data?.courses);
      setMainImageBackup(dataImageDetail?.data?.bannerImage);
    }
  }
};

export const getUpdatedImageDetails = (
  dataUpdateImageDetails,
  fetchKeywords,
  fetchImageList,
  setNotificationData,
  filterData,
  search,
  makeRequest,
  setSearchFilterEmptyState,
  setErrorStateTemplate,
  isOnline,
  setImageList,
  setImageListAll,
  searchAndSort,
  setFilterDropdown,
  setCategory,
  setKeywordSuggestion,
  setKeywordSuggestionCopy,
) => {
  if (dataUpdateImageDetails) {
    fetchKeywords({
      makeRequest,
      setKeywordSuggestion,
      setKeywordSuggestionCopy,
    });
    if (dataUpdateImageDetails.status === 'Success') {
      //setImageDetailDrawerUpdated(true);
      fetchImageList({
        filterData,
        search,
        makeRequest,
        setSearchFilterEmptyState,
        setErrorStateTemplate,
        isOnline,
        setImageList,
        setImageListAll,
        searchAndSort,
        setFilterDropdown,
        setCategory,
      });
    }
    if (dataUpdateImageDetails.status === 'Fail') {
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Unsaved Changes',
        description:
          'Due to some technical reasons, details for the ImageName were not saved. Please update the details again to make changes. We apologise for the inconvience.',
      });
      //setApiStatus("");
    }
  }
};

export const setDataUpdatedNotification = (
  isImageDetailDrawerUpdated,
  isDrawer,
  imageinput,
  setNotificationData,
  setImageDetailDrawerUpdated,
) => {
  if (isImageDetailDrawerUpdated) {
    if (isDrawer === false) {
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: 'Saved Changes',
        description: `Details for the ${imageinput} were saved.`,
      });
      setImageDetailDrawerUpdated(false);
    }
  }
};

export const setTempPredition = (ImageList, setPredictedSearch) => {
  let tempPredition = ImageList.map((item) => ({
    id: item?.imageId && item?.imageId !== null && item.imageId !== undefined ? item?.imageId : '',
    label:
      item?.fileName && item?.fileName !== null && item.fileName !== undefined
        ? item?.fileName
        : '',
  }));

  if (tempPredition.length > 0 && event?.target?.value?.length > 1) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const updateImageName = (ImageDetail, value, setFileError, setImageInput, usedFileName) => {
  if (
    (ImageDetail && ImageDetail?.fileName !== value && usedFileName?.length > 0) ||
    value?.length < 3
  ) {
    setFileError(true);
  } else {
    setFileError(false);
  }
  if (value.length <= 30) {
    setImageInput(value);
  }
};

export const getCalculatedWidth = (isDrawer, width) => {
  if (isDrawer && width >= 1024) {
    return '21rem';
  } else if (!isDrawer && width >= 1024) {
    return '25rem';
  } else if (!isDrawer && width > 768 && width < 1024) {
    return '16rem';
  } else if (!isDrawer && width >= 375 && width < 768) {
    return '100%';
  } else if (!isDrawer && width > 320) {
    return '100%';
  } else {
    return '16rem';
  }
};

export const setTags = (
  tempList,
  updatedKeywordSuggestionCopy,
  selectedKeyword,
  setImageDetailDrawerUpdated,
  updateImageDetails,
  setImageDetail,
) => {
  let tempObj = [];
  updatedKeywordSuggestionCopy.map((item) => {
    tempObj.push(item.title);
  });

  if (
    selectedKeyword?.title?.length > 0 &&
    tempObj.includes(selectedKeyword?.title) &&
    !tempList.includes(selectedKeyword?.title)
  ) {
    tempList.push(selectedKeyword.title);
    setImageDetail((preState) => ({
      ...preState,
      tags: [...tempList],
    }));
    setImageDetailDrawerUpdated(true);
    updateImageDetails({['tags']: tempList.join(',')});
  }
};
