import React from 'react';
import {useEffect} from 'react';
import styles from './CourseTypeBannerViewOnly.module.scss';
const CourseTypeBannerViewOnly = (props) => {
  const {isOpenClose, setInfoIisClicked, width, TypeTitles, headerOfBanner = ''} = props;
  // console.log('>>>> >>>> CourseTypeBannerViewOnly >>>> props', props);

  const baseClass = (item) => {
    return item === 'Required'
      ? 'req_icon'
      : item === 'Recommended'
      ? 'recom_icon'
      : item === 'Quick Start'
      ? 'qstart_icon'
      : item === 'General Visibility'
      ? 'gen_visiblity_icon'
      : '';
  };

  return (
    <React.Fragment>
      <div
        className={[styles.info_btn_wrapper, isOpenClose ? styles.isClicked : ''].join(' ')}
        onClick={() => {
          if (width < 1200) {
            setInfoIisClicked(!isOpenClose);
          }
        }}
        onMouseOver={(e) => {
          setInfoIisClicked(true);
        }}
        onMouseOut={(e) => {
          setInfoIisClicked(false);
        }}
      >
        <div className={styles.info_btn}></div>
        {/* CourseType Banner (view only) */}
        <div className={styles.course_type_read_banner}>
          <div className={styles.tooltip}></div>
          <div className={styles.title}>{headerOfBanner}</div>
          <div className={styles.course_types}>
            {TypeTitles?.map((item, index) => {
              return (
                <div className={[styles.item_wrapper, styles.banner_details].join(' ')} key={index}>
                  <div className={`${styles.course_type_icon} ${styles[baseClass(item)]}`}></div>
                  <div className={styles.item}>{item}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseTypeBannerViewOnly;
