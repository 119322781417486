import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import Header from '../shared/header';
import DeleteModal from './DeleteModal';
import CoursesModal from './CoursesModal';
import styles from './Audience.module.scss';
import LearningPathModalComponent from './learningPathModal';
import CreateUserGroupComponent from './createUserGroup';
import {connect} from 'react-redux';
import TopBarComponenent from '../audienceManagement/TopBar';
import TableListComponent from '../audienceManagement/TableComponent';
import {findIndex, find} from 'lodash';
import {setSelectedNavigationOption, setIsUserLoggedIn} from '../../redux/actions/uiActions';
import DrawerComponent from './Drawer';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import EmptyScreenComponent from '../shared/emptyScreen';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import Notification from '../shared/notification';
import config from '../../config';
import {useHistory} from 'react-router-dom';
import {
  handleFilterApply,
  handleCloseLearningModal,
  handleCloseCoursesModal,
  handleCloseNewUserGroup,
  handleSaveDeleteChanges,
  _filterData,
  handleSearchInput,
  fetchAudienceGroupList,
  handleCloseDeleteModal,
  selectedAudiGrp,
  fetchAudienceDetails,
} from '../utils/AudienceManagementUtils/LandingPageUtils';
import ManageLearningPathModal from '../learningPathManagement/ManageCourseModal/ManageCourseModal';

const AudienceManagementComponent = ({
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
  notificationData,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isCollapse, setCollapsible] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isCourses, setCourses] = useState(false);
  const [isLearning, setLearning] = useState(false);
  const [isNewUserGroup, setNewUserGroup] = useState(false);
  const [rowId, setRowId] = useState(0);
  const [search, setSearch] = useState('');
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [isDrawer, setDrawer] = useState(false);
  const [width, setWidth] = useState(0);
  const [predictionList, setPredictionList] = useState([]);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  const [audienceGroupListCopy, setAudienceGroupListCopy] = useState([]);
  const [isOnline, setOnline] = useState(true);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [audienceDetails, setAudienceDetails] = useState({});
  const [predictedSearch, setPredictedSearch] = useState('');
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('AUDIENCE-MANAGEMENT');
    setOnline(navigator.onLine);
    fetchAudienceGroupList({
      sortBy: '',
      makeRequest,
      setSearchFilterEmptyState,
      setAudienceGroupList,
      setAudienceGroupListCopy,
      setErrorStateTemplate,
      isOnline,
      setIsLoading,
    });
    if (query?.UserGroupId?.length > 0) {
      fetchAudienceDetails({
        AudiId: query?.UserGroupId,
        makeRequest,
        setAudienceDetails,
        setDrawer,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
      });
    }
  }, []);

  const [filterData, setFilterData] = useState(_filterData);

  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'networkError',
    });
    window.location.reload();
  });

  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'networkError',
    });
  });

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let result;
      result = audienceGroupListCopy.filter((user) =>
        user.audienceGrpName.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      result?.sort(function (one, other) {
        return one?.audienceGrpName?.length - other?.audienceGrpName?.length;
      });
      //for checking the serach char index in audi group name
      result?.sort(function (one, other) {
        return (
          one.audienceGrpName?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.audienceGrpName?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      setAudienceGroupList(result);
    } else {
      setAudienceGroupList(audienceGroupListCopy);
    }
  }, [search]);

  return (
    <React.Fragment>
      <div className={[styles.audience_mgt_wrapper, isNewUserGroup].join(' ')}>
        {/* <div className={styles.header_main_wrapper}>
         <div className={styles.header_wrapper}>
           <div className={styles.heading}>Audience Management</div>
         </div>
       </div> */}
        {/* Header Component */}
        <Header
          headerName="User Group Management"
          headerXSName=""
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={false}
          isBtn1={false}
          isBtn2={false}
        />
        {/* Header Component */}
        {/* Learning Empty error container */}
        {/* {audienceGroupList?.length == 0 &&  (
          <div className={[styles.am_empty_screen_wrapper, ''].join(' ')}>
            <div className={styles.inner_wrapper_empty}>
              <div className={styles.empty_screen}>
                <div className={[styles.image_wrapper, styles.am_image].join(' ')}></div>

                <div className={styles.content_wrapper}>
                  <div className={styles.label}>User Group Management</div>
                  <div className={styles.content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* End of empty screen */}

        {
          <div className={[styles.audience_wrapper].join(' ')}>
            <TopBarComponenent
              list={audienceGroupList}
              fetchList={(params) => {
                fetchAudienceGroupList({
                  sortBy: `${params[0]?.paramForAPI?.toLowerCase()}`,
                  makeRequest,
                  setSearchFilterEmptyState,
                  setAudienceGroupList,
                  setAudienceGroupListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  setIsLoading,
                });
              }}
              searchListFunction={(e) => {
                if (e != '') {
                  setSearch(e.target.value);
                } else if (e == '') {
                  setSearch('');
                }
              }}
              placeholderName={'Search User Group'}
              setPredictedSearch={setPredictedSearch}
              predictedSearch={predictedSearch}
              handleSearchInput={(event) => {
                handleSearchInput({
                  event,
                  setSearch,
                  setPredictedSearch,
                  audienceGroupList,
                });
              }}
              handleFilterApply={(params) => {
                handleFilterApply({
                  params,
                  filterData,
                  findIndex,
                  fetchAudienceGroupList,
                  makeRequest,
                  setSearchFilterEmptyState,
                  setAudienceGroupList,
                  setAudienceGroupListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  setIsLoading,
                });
              }}
              filterData={filterData}
              isOpenSubCategories={true}
              isDrawer={isDrawer}
              setDrawer={setDrawer}
            />

            <div className={styles.inner_wrapper}>
              <TableListComponent
                isLoading={isLoading}
                UGList={true}
                list={audienceGroupList}
                EmptyScreenComponent={EmptyScreenComponent}
                isSearchFilterEmptyState={isSearchFilterEmptyState}
                onClickAudienceGrp={(e, item) => {
                  selectedAudiGrp({
                    e,
                    item,
                    isDrawer,
                    audienceDetails,
                    setDrawer,
                    setAudienceDetails,
                    makeRequest,
                    setDrawerAPIFailed,
                    drawerIdForBrokenLink,
                    setDrawerIdForBrokenLink,
                  });
                }}
                drawerDetails={audienceDetails}
                drawerOpen={isDrawer}
                drawerIdForBrokenLink={drawerIdForBrokenLink}
              />

              <DrawerComponent
                drawerDetails={audienceDetails}
                drawerOpen={isDrawer}
                drawerClose={setDrawer}
                isAPIFailed={isDrawerAPIFailed}
                drawerTryAgainCall={(drawerId) => {
                  fetchAudienceDetails({
                    AudiId: drawerId,
                    makeRequest,
                    setAudienceDetails,
                    setDrawer,
                    setDrawerAPIFailed,
                    drawerIdForBrokenLink,
                    setDrawerIdForBrokenLink,
                  });
                }}
                drawerIdForBrokenLink={drawerIdForBrokenLink}
              />
            </div>
          </div>
        }
        {/* Delete Modal */}
        {isDelete ? (
          <DeleteModal
            closeDeleteModal={handleCloseDeleteModal({setDelete})}
            saveDeleteChanges={handleSaveDeleteChanges({setDelete})}
          />
        ) : null}
        {/* Delete Modal */}

        {/* Courses Modal */}
        {isCourses ? (
          <CoursesModal closeCoursesModal={handleCloseCoursesModal({setCourses, isCourses})} />
        ) : null}
        {/* Courses Modal */}

        {/* Learning Modal */}
        {isLearning ? (
          <LearningPathModalComponent
            closeLearningModal={handleCloseLearningModal({setLearning, isLearning})}
          />
        ) : null}
        {/* Learning Modal */}
      </div>
      {/* Create User Group */}
      {isNewUserGroup ? (
        <CreateUserGroupComponent closeNewUserGroup={handleCloseNewUserGroup({setNewUserGroup})} />
      ) : null}
      {/* Create User Group */}

      {/* Manage Learning Path Starts */}
      <ManageLearningPathModal />
      {/* Manage Learning Path Ends */}
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(AudienceManagementComponent),
);
