import {useState} from 'react';
import {makeRequest} from '../APIsUtils/httpsUtils';
export const fetchCategorySkillsTags = async (props) => {
  const {
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  } = props;

  const data = await makeRequest({
    method: 'GET',
    endpoint: '/admin/api/v1/csts',
  });
  if (data != 'ERR_NETWORK' && data) {
    setCategorgyList(data?.data);
    setCategorgyListCopy(data?.data);
    setSearchFilterEmptyState(true);
  } else if (data == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchCategorySkillsTagsListWithParams = async (props) => {
  const {
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
  } = props;
  //console.log('>>>> >>>> fetchCategorySkillsTagsListWithParams >>>> props', props);

  let endpoint = '/admin/api/v1/csts';
  if (sortBy) {
    endpoint += `?sortBy=${sortBy?.toLowerCase()}`;
  }
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    let newDataCourses = [];
    newDataCourses = dataCourses?.data;
    let tilesLables = [];

    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(ele?.title != 'Categories' ? ele?.title.slice(0, -1) : 'Category');
        }
      }

      if (tilesLables?.length > 0) {
        newDataCourses = dataCourses?.data.filter((ele) => {
          return tilesLables.includes(ele?.type);
        });
      }
    }
    setCategorgyList(newDataCourses);
    setCategorgyListCopy(newDataCourses);
    setSearchFilterEmptyState(true);
  } else if (dataCourses == 'ERR_NETWORK') {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
  }
};

export const fetchCategorySkillsTagsDetails = async (props) => {
  const {
    CSTId,
    makeRequest,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    type,
    setTranslationNames,
    fromRefreshList = false,
    variant = '',
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  } = props;

  const endpoint = `/admin/api/v1/csts/${CSTId}?type=${type}`;
  let audienceDetailsData = await makeRequest({
    method: 'GET',
    endpoint,
  });

  if (audienceDetailsData != 'ERR_NETWORK' && audienceDetailsData && fromRefreshList === false) {
    setCategorySkillsTagsDetails(audienceDetailsData?.data);
    setDrawerAPIFailed(false);
    setDrawer(true);
    setTranslationNames({
      english_Translation: audienceDetailsData?.data?.englishTranslation,
      chinese_Translation: audienceDetailsData?.data?.chineseTranslation,
    });
    setCoursesDrawerList(audienceDetailsData?.data?.courses);
    setLearningPathsDrawerList(audienceDetailsData?.data?.learningPaths);
    setTranslationNamesIntialCopy({
      english_Translation: audienceDetailsData?.data?.englishTranslation,
      chinese_Translation: audienceDetailsData?.data?.chineseTranslation,
    });
  } else if (
    audienceDetailsData != 'ERR_NETWORK' &&
    audienceDetailsData &&
    fromRefreshList === true
  ) {
    if (variant === 'courses') {
      if (audienceDetailsData?.data?.courses?.length !== coursesDrawerList?.length) {
        fetchCategorySkillsTags({
          setCategorgyList,
          setSearchFilterEmptyState,
          setCategorgyListCopy,
          setErrorStateTemplate,
          isOnline,
        });
      }
      setCoursesDrawerList(audienceDetailsData?.data?.courses);
    } else {
      if (audienceDetailsData?.data?.learningPaths?.length !== learningPathsDrawerList?.length) {
        fetchCategorySkillsTags({
          setCategorgyList,
          setSearchFilterEmptyState,
          setCategorgyListCopy,
          setErrorStateTemplate,
          isOnline,
        });
      }
      setLearningPathsDrawerList(audienceDetailsData?.data?.learningPaths);
    }
  } else if (audienceDetailsData == 'ERR_NETWORK') {
    if (CSTId != undefined && CSTId !== drawerIdForBrokenLink) {
      setDrawerIdForBrokenLink(CSTId);
    }
    setDrawer(true);
    setDrawerAPIFailed(true);
  }
};

export const handleSearchInput = (props) => {
  const {event, setSearch, setPredictedSearch, categorgyList} = props;
  setSearch(event?.target?.value);
  setPredictedSearch('');

  let tempPredition = categorgyList?.map((item) => ({
    id: item?.id && item?.id !== null && item.id !== undefined ? item?.id : '',
    label:
      item?.englishTranslation &&
      item?.englishTranslation !== null &&
      item.englishTranslation !== undefined
        ? item?.englishTranslation
        : '',
  }));
  // let tempPredition2 = categorgyList?.map((item) => ({
  //   id: item?.id && item?.id !== null && item.id !== undefined ? item?.id : '',
  //   label:
  //     item?.englishTranslation &&
  //     item?.englishTranslation !== null &&
  //     item.englishTranslation !== undefined
  //       ? item?.englishTranslation
  //       : '',
  // }));

  // let a = tempPredition.concat(tempPredition2);

  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const handleFilterApplyNew = async (props) => {
  const {
    params,
    filterData,
    makeRequest,
    setFilterData,
    setFilterDataTemp,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    setIsLoading,
  } = props;
  //console.log('>>>> >>>> handleFilterApplyNew >>>> props', props);
  setIsLoading(true);
  let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
  const categoryId = [];
  let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');

  for (const ele of categoriesArray) {
    ele.checked && categoryId.push(ele.id);
  }
  let bodyVar = {
    categoryId,
  };
  let sortBy = '';
  let sortByName = 'Recent Activity';
  let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
  radioFilterArray.forEach((ele) => {
    switch (ele?.name) {
      case 'Recent Activity':
        (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
        break;
      case 'A - Z':
        (sortBy = 'A_Z'), (sortByName = 'A - Z');
        break;
      case 'Z - A':
        (sortBy = 'Z_A'), (sortByName = 'Z - A');
        break;
      case 'Newest To Oldest':
        (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
        break;
      case 'Oldest To Newest':
        (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
        break;
      default:
        sortBy = 'Recent_Activity';
        break;
    }
  });
  let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
  sortByData.forEach((data) => {
    if (data?.name == sortByName) {
      data.checked = true;
    } else {
      data.checked = false;
    }
  });
  const _filterData = {
    tiles: tilesFilterArray,
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: sortByData,
        },
      },
    ],
  };
  setFilterData(_filterData);
  setFilterDataTemp(new Object(_filterData));
  await fetchCategorySkillsTagsListWithParams({
    bodyVar,
    sortBy,
    tilesFilterArray,
    makeRequest,
    setCategorgyList,
    setCategorgyListCopy,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const closeDrawerModal = async (props) => {
  const {
    englishTranslationLength,
    chineseTranslationLength,
    drawerClose,
    drawerOpen,
    drawerDetails,
    setChineseTranslationEmpty,
    setEnglishTranslationEmpty,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    handleCSTTranslationChange,
    translationNamesIntialCopy,
    translationNames,
    setEnglishTranslationDuplicetName,
    englishTranslationDuplicetName,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    filterData,
    nameErrorStateChinese,
    nameErrorStateEnglish,
  } = props;
  let sortBy = '';
  filterData?.filtersList[0]?.filterTypeData?.sectionData.find((item) => {
    if (item?.checked === true) {
      sortBy = item?.name;
    }
  });
  switch (sortBy) {
    case 'Recent Activity':
      sortBy = 'Recent_Activity';
      break;
    case 'A - Z':
      sortBy = 'A_Z';
      break;
    case 'Z - A':
      sortBy = 'Z_A';
      break;
    case 'Newest To Oldest':
      sortBy = 'Newest_To_Oldest';
      break;
    case 'Oldest To Newest':
      sortBy = 'Oldest_To_Newest';
      break;
    default:
      sortBy = 'Recent_Activity';
      break;
  }

  if (
    englishTranslationLength !== 0 &&
    chineseTranslationLength !== 0 &&
    englishTranslationDuplicetName === false &&
    !nameErrorStateChinese === true &&
    !nameErrorStateEnglish === true
  ) {
    drawerClose(!drawerOpen);
    if (
      translationNames?.english_Translation?.trim() !==
        translationNamesIntialCopy?.english_Translation?.trim() ||
      translationNames?.chinese_Translation?.trim() !==
        translationNamesIntialCopy?.chinese_Translation?.trim()
    ) {
      await handleCSTTranslationChange({
        type: drawerDetails?.type,
        CSTId: drawerDetails?.id,
        translationNames,
        setNotificationData,
        translationNamesIntialCopy,
        setDashboardNotificationCountApi,
        setNotificationListApi,
      });
      // await fetchCategorySkillsTags({
      //   setCategorgyList,
      //   setSearchFilterEmptyState,
      //   setCategorgyListCopy,
      //   setErrorStateTemplate,
      //   isOnline,
      // });
      fetchCategorySkillsTagsListWithParams({
        sortBy,
        tilesFilterArray: filterData?.tiles,
        makeRequest,
        setCategorgyList,
        setCategorgyListCopy,
        setSearchFilterEmptyState,
        setErrorStateTemplate,
      });
    }
  }
  if (englishTranslationLength === 0 && chineseTranslationLength === 0) {
    setEnglishTranslationEmpty(true);
    setChineseTranslationEmpty(true);
  } else if (englishTranslationLength === 0) {
    setEnglishTranslationEmpty(true);
  } else if (chineseTranslationLength === 0) {
    setChineseTranslationEmpty(true);
  }
};

export const handleErrorStatesChinese = (props) => {
  const {
    e,
    setMaxCharErrorMessageChinese,
    regexNameChinese,
    setNameErrorStateChinese,
    regexNameChinese2,
    setChineseTranslationInvalidCharacter,
  } = props;
  // console.log('>>>> >>>> handleErrorStatesChinese >>>> props', props);

  let errorString = makeAnagram({
    a: e.target.value,
    b: e.target.value?.toString()?.replaceAll(regexNameChinese, ''),
  });
  setChineseTranslationInvalidCharacter(errorString);
  if (e.target.value?.toString()?.replaceAll(regexNameChinese, '')?.length == 30) {
    setMaxCharErrorMessageChinese(true);
  } else {
    setMaxCharErrorMessageChinese(false);
  }
  if (e.target.value?.toString()?.replaceAll(regexNameChinese2, '')) {
    setNameErrorStateChinese(true);
  } else {
    setNameErrorStateChinese(false);
  }
};

export const handleErrorStatesEnglish = (props) => {
  const {
    e,
    setNameErrorStateEnglish,
    regexNameChinese2,
    setMaxCharErrorMessageEnglish,
    regexNameChinese,
    list,
    drawerDetails,
    setEnglishTranslationDuplicetName,
    setEnglishTranslationInvalidCharacter,
  } = props;
  // console.log(">>>> >>>> handleErrorStatesEnglish >>>> props",props)

  let errorString = makeAnagram({
    a: e,
    b: e?.toString()?.replaceAll(regexNameChinese, ''),
  });
  setEnglishTranslationInvalidCharacter(errorString);

  let resultCategory = [];
  let resultSill = [];
  let resultTag = [];
  if (e?.toString()?.replaceAll(regexNameChinese2, '')) {
    setNameErrorStateEnglish(true);
  } else {
    setNameErrorStateEnglish(false);
  }
  if (e?.toString()?.replaceAll(regexNameChinese, '')?.length == 30) {
    setMaxCharErrorMessageEnglish(true);
  } else {
    setMaxCharErrorMessageEnglish(false);
  }

  list?.find((item) => {
    if (item?.type === 'Category' && drawerDetails?.type === 'Category') {
      resultCategory?.push(item);
    } else if (item?.type === 'Skill' && drawerDetails?.type === 'Skill') {
      resultSill?.push(item);
    } else if (item?.type === 'Tag' && drawerDetails?.type === 'Tag') {
      resultTag?.push(item);
    }
  });
  if (drawerDetails?.type === 'Category') {
    if (
      resultCategory?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  } else if (drawerDetails?.type === 'Skill') {
    if (
      resultSill?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  } else if (drawerDetails?.type === 'Tag') {
    if (
      resultTag?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase()?.trim() == e?.toLowerCase()?.trim();
      }) &&
      drawerDetails?.englishTranslation?.toLowerCase()?.trim() != e?.toLowerCase()?.trim()
    ) {
      setEnglishTranslationDuplicetName(true);
    } else {
      setEnglishTranslationDuplicetName(false);
    }
  }
};

export const handleOnClickEditCourse = (props) => {
  const {id = 1, state = ''} = props;
  const url = '/create-course-wizard';

  let subheader =
    state == 'Draft' ? 'course_description' : state == 'Live' || state == 'Offline' ? 'edit' : '';
  window.open(url + '?id=' + id + '&' + 'sub_header=' + subheader, '_blank', 'noopener,noreferrer');
};

export const handleOnClickEditLearningPath = (props) => {
  const {id = '', state = ''} = props;

  const url = '/newLearningPath';
  let subheader =
    state == 'Draft'
      ? 'learning_path_description'
      : state == 'Live' || state == 'Offline'
      ? 'categories_skills_tags'
      : '';
  window.open(url + '?id=' + id + '&' + 'sub_header=' + subheader, '_blank', 'noopener,noreferrer');
};

export const handleCloseCST = (props) => {
  const {setDelete, isDelete} = props;
  //console.log(">>>> >>>> handleCloseCST >>>> props",props)

  setDelete(!isDelete);
};

export const handleCSTTranslationChange = async (props) => {
  const {
    type = '',
    CSTId = '',
    translationNames,
    setNotificationData,
    translationNamesIntialCopy,
    setDashboardNotificationCountApi,
    setNotificationListApi,
  } = props;
  // console.log(">>>> >>>> handleCSTTranslationChange >>>> props",props)

  let change = {
    englishTranslation: translationNames?.english_Translation?.trim(),
    chineseTranslation: translationNames?.chinese_Translation?.trim(),
  };

  const endpoint = `/admin/api/v1/csts/${CSTId}?type=${type}`;
  let data = await makeRequest({
    method: 'PUT',
    endpoint,
    body: change,
  });
  if (data != 'ERR_NETWORK' && data != 'ERR_BAD_REQUEST' && data) {
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: `${type} Edited Successfully`,
      // description: `Please upload .zip, .rar or .7zip files only!`,
      description: `Translation edited.`,
    });
    setNotificationListApi();
    setDashboardNotificationCountApi();
  }
};

export const refreshList = async (props) => {
  const {
    variant = '',
    drawerId = '',
    type = '',
    setLoadingCoursesList,
    setLoadingLearningPathList,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setTranslationNames,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  } = props;
  //console.log(">>>> >>>> refreshList >>>> props",props)

  if (variant === 'courses') {
    setLoadingCoursesList(true);
  } else if (variant === 'learningPaths') {
    setLoadingLearningPathList(true);
  }
  await fetchCategorySkillsTagsDetails({
    CSTId: drawerId,
    makeRequest,
    setCategorySkillsTagsDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setTranslationNames,
    type,
    fromRefreshList: true,
    variant: variant,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  });
  if (variant === 'courses') {
    setLoadingCoursesList(false);
  } else if (variant === 'learningPaths') {
    setLoadingLearningPathList(false);
  }
};

export const handleDeleteCST = async (props) => {
  const {
    categorySkillsTagsDetails,
    setDrawer,
    setNotificationData,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setNotificationListApi,
    setDashboardNotificationCountApi,
    filterData,
  } = props;
  //console.log(">>>> >>>> handleDeleteCST >>>> props",props)
  let sortBy = '';
  filterData?.filtersList[0]?.filterTypeData?.sectionData.find((item) => {
    if (item?.checked === true) {
      sortBy = item?.name;
    }
  });
  switch (sortBy) {
    case 'Recent Activity':
      sortBy = 'Recent_Activity';
      break;
    case 'A - Z':
      sortBy = 'A_Z';
      break;
    case 'Z - A':
      sortBy = 'Z_A';
      break;
    case 'Newest To Oldest':
      sortBy = 'Newest_To_Oldest';
      break;
    case 'Oldest To Newest':
      sortBy = 'Oldest_To_Newest';
      break;
    default:
      sortBy = 'Recent_Activity';
      break;
  }
  //console.log('>>>> >>>> sortBy', sortBy);
  let endpoint = `/admin/api/v1/csts/${categorySkillsTagsDetails?.id}?type=${categorySkillsTagsDetails?.type}`;
  let dataDeleteLearningPath = await makeRequest({
    method: 'DELETE',
    endpoint: endpoint,
  });
  if (
    dataDeleteLearningPath != 'ERR_NETWORK' &&
    dataDeleteLearningPath != 'ERR_BAD_REQUEST' &&
    dataDeleteLearningPath
  ) {
    setDrawer(false);
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: `${categorySkillsTagsDetails?.type} deleted Successfully`,
      // description: `Please upload .zip, .rar or .7zip files only!`,
      description: `${categorySkillsTagsDetails?.englishTranslation} deleted.`,
    });
    // fetchCategorySkillsTags({
    //   setCategorgyList,
    //   setSearchFilterEmptyState,
    //   setCategorgyListCopy,
    //   setErrorStateTemplate,
    //   isOnline,
    // });
    fetchCategorySkillsTagsListWithParams({
      sortBy,
      tilesFilterArray: filterData?.tiles,
      makeRequest,
      setCategorgyList,
      setCategorgyListCopy,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
    });
    setNotificationListApi();
    setDashboardNotificationCountApi();
  }
};

export const selectedCategorySkillsTags = async (props) => {
  const {
    e,
    item,
    isDrawer,
    categorySkillsTagsDetails,
    setDrawer,
    setCategorySkillsTagsDetails,
    makeRequest,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    type,
    setTranslationNames,
    setCoursesDrawerList,
    setLearningPathsDrawerList,
    englishTranslationDuplicetName,
    setSelectedCST,
    translationNames,
    translationNamesIntialCopy,
    setEnglishTranslationEmpty,
    setChineseTranslationEmpty,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setEnglishTranslationDuplicetName,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    regexNameChinese,
    setTranslationNamesIntialCopy,
    learningPathsDrawerList,
    coursesDrawerList,
    filterData,
    nameErrorStateChinese,
    nameErrorStateEnglish,
  } = props;
  // console.log(">>>> >>>> selectedCategorySkillsTags >>>> props", props);
  setSelectedCST(type);
  if (
    isDrawer &&
    e.currentTarget.id === categorySkillsTagsDetails?.id &&
    translationNames?.chinese_Translation?.length !== 0 &&
    translationNames?.english_Translation?.length !== 0 &&
    englishTranslationDuplicetName === false &&
    nameErrorStateEnglish === false &&
    nameErrorStateChinese === false
  ) {
    //console.log(">>>> >>>> if")
    setDrawer(false);
    setCategorySkillsTagsDetails(null);
    if (
      translationNames?.english_Translation !== translationNamesIntialCopy?.english_Translation ||
      translationNames?.chinese_Translation !== translationNamesIntialCopy?.chinese_Translation
    ) {
      closeDrawerModal({
        englishTranslationLength: translationNames?.english_Translation?.length,
        chineseTranslationLength: translationNames?.chinese_Translation?.length,
        drawerClose: setDrawer,
        drawerOpen: isDrawer,
        drawerDetails: categorySkillsTagsDetails,
        setChineseTranslationEmpty: setChineseTranslationEmpty,
        setEnglishTranslationEmpty: setEnglishTranslationEmpty,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        handleCSTTranslationChange,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
        setNotificationData,
        setDashboardNotificationCountApi,
        setNotificationListApi,
        filterData,
        nameErrorStateChinese,
        nameErrorStateEnglish,
      });
    }
  } else if (
    isDrawer &&
    (translationNames?.chinese_Translation?.length === 0 ||
      translationNames?.english_Translation?.length === 0 ||
      englishTranslationDuplicetName === true ||
      nameErrorStateChinese === true ||
      nameErrorStateEnglish === true)
  ) {
    // console.log(">>>> >>>> else if >>>> 1")
    closeDrawerModal({
      englishTranslationLength: translationNames?.english_Translation
        ?.toString()
        ?.replaceAll(regexNameChinese, '')?.length,
      chineseTranslationLength: translationNames?.chinese_Translation
        ?.toString()
        ?.replaceAll(regexNameChinese, '')?.length,
      drawerClose: setDrawer,
      drawerOpen: isDrawer,
      drawerDetails: categorySkillsTagsDetails,
      setChineseTranslationEmpty: setChineseTranslationEmpty,
      setEnglishTranslationEmpty: setEnglishTranslationEmpty,
      setCategorgyList,
      setSearchFilterEmptyState,
      setCategorgyListCopy,
      setErrorStateTemplate,
      isOnline,
      handleCSTTranslationChange,
      translationNamesIntialCopy,
      translationNames,
      setEnglishTranslationDuplicetName,
      englishTranslationDuplicetName,
      setNotificationData,
      setDashboardNotificationCountApi,
      setNotificationListApi,
      filterData,
      nameErrorStateChinese,
      nameErrorStateEnglish,
    });
  } else if (isDrawer && e.currentTarget.id !== categorySkillsTagsDetails?.id) {
    // console.log(">>>> >>>> else if >>>> 2",e.currentTarget.id,item)
    if (
      translationNames?.english_Translation !== translationNamesIntialCopy?.english_Translation ||
      translationNames?.chinese_Translation !== translationNamesIntialCopy?.chinese_Translation
    ) {
      closeDrawerModal({
        englishTranslationLength: translationNames?.english_Translation
          ?.toString()
          ?.replaceAll(regexNameChinese, '')?.length,
        chineseTranslationLength: translationNames?.chinese_Translation
          ?.toString()
          ?.replaceAll(regexNameChinese, '')?.length,
        drawerClose: setDrawer,
        drawerOpen: isDrawer,
        drawerDetails: categorySkillsTagsDetails,
        setChineseTranslationEmpty: setChineseTranslationEmpty,
        setEnglishTranslationEmpty: setEnglishTranslationEmpty,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
        handleCSTTranslationChange,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
        setNotificationData,
        setDashboardNotificationCountApi,
        setNotificationListApi,
        filterData,
        nameErrorStateChinese,
        nameErrorStateEnglish,
      });
      fetchCategorySkillsTagsDetails({
        CSTId: item,
        makeRequest,
        setCategorySkillsTagsDetails,
        setDrawer,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
        type,
        setTranslationNames,
        setCoursesDrawerList,
        setLearningPathsDrawerList,
        setTranslationNamesIntialCopy,
        learningPathsDrawerList,
        coursesDrawerList,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
      });
    } else {
      fetchCategorySkillsTagsDetails({
        CSTId: item,
        makeRequest,
        setCategorySkillsTagsDetails,
        setDrawer,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
        type,
        setTranslationNames,
        setCoursesDrawerList,
        setLearningPathsDrawerList,
        setTranslationNamesIntialCopy,
        learningPathsDrawerList,
        coursesDrawerList,
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
      });
    }
  } else {
    // console.log(">>>> >>>> else ")
    fetchCategorySkillsTagsDetails({
      CSTId: item,
      makeRequest,
      setCategorySkillsTagsDetails,
      setDrawer,
      setDrawerAPIFailed,
      drawerIdForBrokenLink,
      setDrawerIdForBrokenLink,
      type,
      setTranslationNames,
      setCoursesDrawerList,
      setLearningPathsDrawerList,
      setTranslationNamesIntialCopy,
      learningPathsDrawerList,
      coursesDrawerList,
      setCategorgyList,
      setSearchFilterEmptyState,
      setCategorgyListCopy,
      setErrorStateTemplate,
      isOnline,
    });
  }
};

export const initialUseEffect = async (props) => {
  const {
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setSelectedNavigationOption,
    setIsUserLoggedIn,
    setIsLoading,
  } = props;
  //console.log(">>>> >>>> initialUseEffect >>>> props",props)

  setIsUserLoggedIn(true);
  setSelectedNavigationOption('CATEGORY-SKILLS-MANAGEMENT');
  await fetchCategorySkillsTags({
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const handleTranslationChange = (props) => {
  const {e, variant, setTranslationNames, translationNames, regexNameChinese} = props;
  //console.log(">>>> >>>> handleTranslationChange >>>> props",props)
  //console.log(">>>> >>>> handleTranslationChange >>>> e?.target?.value",e.target.value?.toString()?.replaceAll(regexNameChinese, ''))

  if (e?.target?.value[0] !== ' ') {
    if (variant === 'courses') {
      setTranslationNames({
        english_Translation: e?.target?.value?.replaceAll(/\s+/g, ' '),
        chinese_Translation: translationNames?.chinese_Translation,
      });
    } else {
      setTranslationNames({
        english_Translation: translationNames?.english_Translation,
        chinese_Translation: e?.target?.value?.replaceAll(/\s+/g, ' '),
      });
    }
  }
};

export const searchVariableUseEffect = (props) => {
  const {search, categorgyListCopy, setCategorgyList} = props;
  //console.log(">>>> >>>> searchVariableUseEffect >>>> props",props)

  if (search && search.length && search.length >= 1) {
    let result;
    result = categorgyListCopy.filter((user) =>
      user.englishTranslation.toLowerCase().includes(search.toLowerCase()),
    );
    //for checking length of CST name
    result?.sort(function (one, other) {
      return one?.englishTranslation?.length - other?.englishTranslation?.length;
    });
    //for checking the serach char index in CST name
    result?.sort(function (one, other) {
      return (
        one.englishTranslation?.toLowerCase().indexOf(search?.toLowerCase()) -
        other.englishTranslation?.toLowerCase().indexOf(search?.toLowerCase())
      );
    });
    setCategorgyList(result);
  } else {
    setCategorgyList(categorgyListCopy);
  }
};

export const makeAnagram = (props) => {
  const {a, b} = props;
  //console.log('>>>> >>>> makeAnagram >>>> props', props);

  const arr1 = a.split('');
  const arr2 = b.split('');
  const diff1 = arr1.filter((letter) => !arr2.includes(letter));
  const diff2 = arr2.filter((letter) => !arr1.includes(letter));
  let str = '';
  str = diff1 + diff2;
  // console.log(">>>> diff1 + diff2",diff1 + diff2, " >>>> >>>> typeof diff1 + diff2", typeof diff1 + diff2)

  return str.replaceAll(',', '');
};
