import React from 'react';
import styles from './drawerDropDownListWithInfoIcon.module.scss';
import CourseTypeBannerViewOnly from '../CourseTypeBannerViewOnly';
import StatusLabel from '../StatusLabel';
const DrawerDropDownListWithInfoIcon = (props) => {
  const {
    openManageCourses,
    setCoursesDrawerList,
    isCoursesDrawerList,
    drawerDetailsList,
    drawerDetailsCount,
    isInfoIisClicked,
    width,
    setInfoIisClicked,
    title,
  } = props;

  const audienceTypeIcon = (props) => {
    const {icon_details} = props;
    // console.log(">>>> >>>> DrawerDropDownListWithInfoIcon >>>> audienceTypeIcon >>>> icon_details",icon_details,props)
    switch (icon_details) {
      case 'Quick Start':
        return styles.qstart_icon;
      case 'Required':
        return styles.req_icon;
      case 'General visibility':
        return styles.gen_visiblity_icon;
      case 'Recommended':
        return styles.recom_icon;
      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.insturction_text_wrapper}>
        <div
          className={styles.info_text}
          onClick={() => {
            openManageCourses();
          }}
        >
          {`Manage ${title}`}
        </div>
        <div
          className={styles.left_arrow_icon}
          onClick={() => {
            openManageCourses();
          }}
        ></div>
      </div>

      <div
        className={[styles.dropdown_container].join(' ')}
        // onClick={() => handleReplaceAllInstance()}
      >
        <div className={styles.top_bar}>
          <div
            className={styles.title}
            onClick={() => {
              setCoursesDrawerList(!isCoursesDrawerList);
            }}
          >
            {`${title} in the User Group (${drawerDetailsCount})`}
            {/* {courseDetails?.length > 0
                              ? `(${courseDetails?.length})`
                              : ""} */}
          </div>
          {/* Added "isClick" class here to open banner on click of info btn on drawer */}
          <CourseTypeBannerViewOnly
            isOpenClose={isInfoIisClicked}
            setInfoIisClicked={setInfoIisClicked}
            width={width}
            TypeTitles={['Required', 'Recommended', 'Quick Start', 'General Visibility']}
            headerOfBanner={'Course Types'}
          />
          <div
            className={[
              styles.toggle_arrow,
              styles.big_icon,
              !isCoursesDrawerList ? styles.up_arrow : styles.down_arrow,
            ].join(' ')}
            onClick={() => {
              setCoursesDrawerList(!isCoursesDrawerList);
            }}
          ></div>
        </div>
        {isCoursesDrawerList && (
          <div className={styles.list_wrapper}>
            {drawerDetailsList?.map((item, index) => {
              return (
                <div className={styles.list} key={index}>
                  {/* keep below both class as it is "mt_instances". */}
                  <div className={[styles.item_wrapper, styles.mt_instances].join(' ')}>
                    <div
                      className={[
                        styles.course_type_icon,
                        audienceTypeIcon({
                          icon_details: item?.courseType || item?.learningPathType,
                        }),
                        styles.mt_class,
                      ].join(' ')}
                    ></div>
                    <div className={styles.item}>{item?.title}</div>
                  </div>
                  <StatusLabel item={item} large={true} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DrawerDropDownListWithInfoIcon;
