import ContentManagementComponent from '../components/contentManagement';
import React, {useState} from 'react';

function contentManagement() {
  const [editImageExtends, seteditImageExtends] = useState('hide');
  const [croppedImg, setCroppedImg] = useState(undefined);
  const [croppedImg1, setCroppedImg1] = useState(undefined);
  const [mainImg, setMainImg] = useState(undefined);
  const [imageLibraryOpen, setImageLibraryOpen] = useState(true);
  const [couserWizard, setCourseWizard] = useState(true);
  const [newCourse, setNewCourseWizard] = useState(false);
  const [thumbnailId, setThumbnailId] = useState(null);

  return (
    <div className="page_content">
      {/* <SideNavigation pageName="CONTENT-MANAGEMENT" /> */}
      <ContentManagementComponent
        editImageExtends={editImageExtends}
        seteditImageExtends={seteditImageExtends}
        croppedImg={croppedImg}
        setCroppedImg={setCroppedImg}
        mainImg={mainImg}
        setMainImg={setMainImg}
        croppedImg1={croppedImg1}
        setCroppedImg1={setCroppedImg1}
        showImageLibrary={imageLibraryOpen}
        addCourseThubnailImage={(value) => {
          setThumbnailId(value);
        }}
        setOpenImageLib={() => {
          setImageLibraryOpen(true);
          setCourseWizard(false);
        }}
        setCloseImageLib={() => {
          setImageLibraryOpen(false);
          setCourseWizard(true);
          setNewCourseWizard(true);
        }}
      />
    </div>
  );
}
export default contentManagement;
