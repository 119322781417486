import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import styles from './createNewCST.module.scss';
import {connect} from 'react-redux';
import {setSelectedNavigationOption, setIsUserLoggedIn} from '../../../redux/actions/uiActions';
import {setErrorStateTemplate} from '../../../redux/actions/uiActions';
import {
  handleCreateCSTSelectTypeDropdown,
  handleCreateErrorStatesChinese,
  handleCreateErrorStatesEnglish,
  handleCreateButtonState,
} from '../../utils/CategoriesSkillsTagsManagementUtils/CreatCST';
const CreateNewCSTComponent = (props) => {
  const {
    openAndCloseCST,
    handleCSTCreatModelCloseOpen,
    mouseHoverState,
    setMouseHoverState,
    selectTypeText,
    setSelectTypeText,
    openSelectTypeDropdown,
    setOpenSelectTypeDropdown,
    handleEnglishTranslationCreateCST,
    handleChineseTranslationCreateCST,
    englishTranslationCreateCST,
    chineseTranslationCreateCST,
    handleCreateNewCSTButtonSaveChanges,
    isCreateEnglishTranslationEmpty,
    setCreateEnglishTranslationEmpty,
    isCreateChineseTranslationEmpty,
    setCreateChineseTranslationEmpty,
    CreateEnglishTranslationDuplicetName,
    setCreateEnglishTranslationDuplicetName,
    CreateMaxCharErrorMessageChinese,
    setCreateMaxCharErrorMessageChinese,
    CreateMaxCharErrorMessageEnglish,
    setCreateMaxCharErrorMessageEnglish,
    setNameErrorStateChineseCreate,
    nameErrorStateChineseCreate,
    setNameErrorStateEnglishCreate,
    nameErrorStateEnglishCreate,
    CreateChineseTranslationLength,
    setCreateChineseTranslationLength,
    CreateEnglishTranslationLength,
    setCreateEnglishTranslationLength,
    list,
    setChineseTranslationCreateCSTInvalidCharacter,
    setEnglishTranslationCreateCSTInvalidCharacter,
    englishTranslationCreateCSTInvalidCharacter,
    chineseTranslationCreateCSTInvalidCharacter,
  } = props;

  const regexNameChinese = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;
  const regexNameChinese2 = /[!^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;

  useEffect(() => {
    handleCreateErrorStatesEnglish({
      e: englishTranslationCreateCST,
      setNameErrorStateEnglishCreate,
      regexNameChinese2,
      setCreateMaxCharErrorMessageEnglish,
      regexNameChinese,
      list,
      drawerDetails: [],
      setCreateEnglishTranslationDuplicetName,
      selectTypeText,
      setEnglishTranslationCreateCSTInvalidCharacter,
    });

    handleCreateErrorStatesChinese({
      e: chineseTranslationCreateCST,
      setCreateMaxCharErrorMessageChinese,
      regexNameChinese,
      setNameErrorStateChineseCreate,
      regexNameChinese2,
      setChineseTranslationCreateCSTInvalidCharacter,
    });
  }, [selectTypeText]);

  const disabledClick = () => {
    //console.log(">>>> >>>> disabledClick >>>> props")
    if (
      englishTranslationCreateCST?.length === 0 &&
      chineseTranslationCreateCST?.length === 0 &&
      selectTypeText != 'Select type'
    ) {
      setCreateEnglishTranslationEmpty(true);
      setCreateChineseTranslationEmpty(true);
    } else if (englishTranslationCreateCST?.length === 0 && selectTypeText != 'Select type') {
      setCreateEnglishTranslationEmpty(true);
    } else if (chineseTranslationCreateCST?.length === 0 && selectTypeText != 'Select type') {
      setCreateChineseTranslationEmpty(true);
    }
  };
  return (
    <React.Fragment>
      {/* Create New Category Modal */}
      {openAndCloseCST ? (
        <div className={[styles.modal_wrapper, ''].join(' ')}>
          <div className={styles.delete_modal}>
            <div className={styles.mob_bar}>
              <div className={styles.line_bar}></div>
            </div>
            <div
              className={styles.close_wrapper}
              onClick={() => {
                handleCSTCreatModelCloseOpen({status: openAndCloseCST});
              }}
            >
              <div className={styles.close_icon} />
            </div>
            <div className={styles.header_wrapper}>
              <div className={styles.heading}>Create New Category/Skill/Tag</div>
            </div>

            <div className={styles.header_wrapper_xs}>
              <div className={styles.heading}>Create New Category/Skill/Tag</div>
            </div>

            {/* Form Fields */}
            <div className={styles.form_main_wrapper}>
              <div className={[styles.form_field, styles.category_dropdown].join(' ')}>
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>Category/Skill/Tag</div>
                </div>

                <div className={[styles.select_main_container].join(' ')}>
                  <div
                    className={
                      openSelectTypeDropdown
                        ? [styles.select_dropdown, styles.active_dropdown].join(' ')
                        : [styles.select_dropdown].join(' ')
                    }
                    onClick={() => {
                      handleCreateCSTSelectTypeDropdown({
                        setOpenSelectTypeDropdown,
                        openSelectTypeDropdown,
                      });
                    }}
                  >
                    {' '}
                    {/* active_dropdown add this class when downdown open */}
                    <div className={styles.txt}>{selectTypeText}</div>
                    <div
                      className={[
                        openSelectTypeDropdown
                          ? [styles.arrow, styles.up_arrow].join(' ')
                          : [styles.arrow, styles.down_arrow].join(' '),
                      ].join(' ')}
                    />
                  </div>

                  {openSelectTypeDropdown ? (
                    <div
                      className={
                        mouseHoverState != 'Select type'
                          ? [styles.dropdown_main_container, styles.selected].join(' ')
                          : [styles.dropdown_main_container].join(' ')
                      }
                    >
                      <div
                        className={[
                          styles.list,
                          mouseHoverState === 'Category' ? styles.selected : null,
                        ].join(' ')}
                        onMouseOver={() => {
                          setMouseHoverState('Category');
                        }}
                        onMouseOut={() => {
                          // setMouseHoverState('');
                          selectTypeText == 'Select type'
                            ? setMouseHoverState('Select type')
                            : setMouseHoverState(selectTypeText);
                        }}
                        onClick={() => {
                          setSelectTypeText('Category');
                          handleCreateCSTSelectTypeDropdown({
                            setOpenSelectTypeDropdown,
                            openSelectTypeDropdown,
                          });
                        }}
                      >
                        Category
                      </div>
                      <div
                        className={[
                          styles.list,
                          mouseHoverState === 'Skill' ? styles.selected : null,
                        ].join(' ')}
                        onMouseOver={() => {
                          setMouseHoverState('Skill');
                        }}
                        onMouseOut={() => {
                          // setMouseHoverState('');
                          selectTypeText == 'Select type'
                            ? setMouseHoverState('Select type')
                            : setMouseHoverState(selectTypeText);
                        }}
                        onClick={() => {
                          setSelectTypeText('Skill');
                          handleCreateCSTSelectTypeDropdown({
                            setOpenSelectTypeDropdown,
                            openSelectTypeDropdown,
                          });
                        }}
                      >
                        Skill
                      </div>
                      <div
                        className={[
                          styles.list,
                          mouseHoverState === 'Tag' ? styles.selected : null,
                        ].join(' ')}
                        onMouseOver={() => {
                          setMouseHoverState('Tag');
                        }}
                        onMouseOut={() => {
                          //setMouseHoverState('');
                          selectTypeText == 'Select type'
                            ? setMouseHoverState('Select type')
                            : setMouseHoverState(selectTypeText);
                        }}
                        onClick={() => {
                          setSelectTypeText('Tag');
                          handleCreateCSTSelectTypeDropdown({
                            setOpenSelectTypeDropdown,
                            openSelectTypeDropdown,
                          });
                        }}
                      >
                        Tag
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                className={[
                  styles.form_field,
                  nameErrorStateEnglishCreate ||
                  isCreateEnglishTranslationEmpty ||
                  CreateEnglishTranslationDuplicetName ||
                  nameErrorStateEnglishCreate
                    ? styles.error_wrapper
                    : null,
                ].join(' ')}
              >
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>English translation*</div>
                  <div className={styles.tag_text}>Max 30 characters</div>
                </div>

                <div className={styles.input_wrapper}>
                  <input
                    type={'text'}
                    placeholder={'Enter English Title'}
                    className={
                      mouseHoverState === 'Category' ||
                      mouseHoverState === 'Skill' ||
                      mouseHoverState === 'Tag'
                        ? styles.input
                        : [styles.input, styles.disabled].join(' ')
                    }
                    maxLength={30}
                    value={englishTranslationCreateCST}
                    onChange={(e) => {
                      handleEnglishTranslationCreateCST({
                        text: e.target.value?.replaceAll(/\s+/g, ' '),
                        regexNameChinese,
                        regexNameChinese2,
                      });
                      handleCreateErrorStatesEnglish({
                        e: e.target.value?.replaceAll(/\s+/g, ' '),
                        setNameErrorStateEnglishCreate,
                        regexNameChinese2,
                        setCreateMaxCharErrorMessageEnglish,
                        regexNameChinese,
                        list,
                        drawerDetails: [],
                        setCreateEnglishTranslationDuplicetName,
                        selectTypeText,
                        setEnglishTranslationCreateCSTInvalidCharacter,
                      });
                      setCreateEnglishTranslationEmpty(false);
                    }}
                  />
                  {CreateMaxCharErrorMessageEnglish &&
                  !nameErrorStateEnglishCreate &&
                  !CreateEnglishTranslationDuplicetName ? (
                    <div className={styles.error_message}>Maximum character limit reached</div>
                  ) : nameErrorStateEnglishCreate &&
                    !CreateMaxCharErrorMessageEnglish &&
                    !isCreateEnglishTranslationEmpty ? (
                    <>
                      <div className={styles.error_icon_red}></div>
                      <div className={styles.error_message}>
                        Valid characters include A-z, 0-9, :, -
                        {/* Invalid characters: {englishTranslationCreateCSTInvalidCharacter} */}
                      </div>
                    </>
                  ) : isCreateEnglishTranslationEmpty ? (
                    <>
                      <div className={styles.error_icon_red}></div>
                      <div className={styles.error_message}>English Title is mandatory</div>
                    </>
                  ) : CreateEnglishTranslationDuplicetName ? (
                    <>
                      <div className={styles.error_icon_red}></div>
                      <div className={styles.error_message}>Name already exists</div>
                    </>
                  ) : null}
                </div>
              </div>

              <div
                className={[
                  styles.form_field,
                  nameErrorStateChineseCreate ||
                  isCreateChineseTranslationEmpty ||
                  nameErrorStateChineseCreate
                    ? styles.error_wrapper
                    : null,
                ].join(' ')}
              >
                <div className={styles.label_wrapper}>
                  <div className={styles.label_text}>Chinese translation*</div>
                  <div className={styles.tag_text}>Max 30 characters</div>
                </div>

                <div className={styles.input_wrapper}>
                  <input
                    type={'text'}
                    placeholder={'Enter Chinese Title'}
                    maxLength={30}
                    className={
                      mouseHoverState === 'Category' ||
                      mouseHoverState === 'Skill' ||
                      mouseHoverState === 'Tag'
                        ? styles.input
                        : [styles.input, styles.disabled].join(' ')
                    }
                    value={chineseTranslationCreateCST}
                    onChange={(e) => {
                      handleChineseTranslationCreateCST({
                        text: e.target.value,
                        regexNameChinese,
                        regexNameChinese2,
                      });
                      handleCreateErrorStatesChinese({
                        e: e.target.value,
                        setCreateMaxCharErrorMessageChinese,
                        regexNameChinese,
                        setNameErrorStateChineseCreate,
                        regexNameChinese2,
                        setChineseTranslationCreateCSTInvalidCharacter,
                      });
                      setCreateChineseTranslationEmpty(false);
                    }}
                  />
                  {CreateMaxCharErrorMessageChinese && !nameErrorStateChineseCreate ? (
                    <div className={styles.error_message}>Maximum character limit reached</div>
                  ) : nameErrorStateChineseCreate &&
                    !CreateMaxCharErrorMessageChinese &&
                    !isCreateChineseTranslationEmpty ? (
                    <>
                      <div className={styles.error_icon_red}></div>
                      <div className={styles.error_message}>
                        Valid characters include A-z, 0-9, :, -
                        {/* Invalid characters: {chineseTranslationCreateCSTInvalidCharacter} */}
                      </div>
                    </>
                  ) : isCreateChineseTranslationEmpty ? (
                    <>
                      <div className={styles.error_icon_red}></div>
                      <div className={styles.error_message}>Chinese Title is mandatory</div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {/* Form Fields */}

            <div className={styles.btn_wrapper}>
              <button
                className={[styles.btn]}
                onClick={() => {
                  handleCSTCreatModelCloseOpen({status: openAndCloseCST});
                }}
              >
                Cancel
              </button>
              <button
                className={[
                  styles.btn,
                  handleCreateButtonState({
                    englishTranslationCreateCST,
                    chineseTranslationCreateCST,
                    isCreateEnglishTranslationEmpty,
                    nameErrorStateChineseCreate,
                    CreateEnglishTranslationDuplicetName,
                    isCreateChineseTranslationEmpty,
                    nameErrorStateEnglishCreate,
                  })
                    ? styles.active
                    : styles.disabled,
                ].join(' ')}
                onClick={async () => {
                  englishTranslationCreateCST?.length > 0 && chineseTranslationCreateCST?.length > 0
                    ? await handleCreateNewCSTButtonSaveChanges()
                    : disabledClick();
                }}
              >
                {' '}
                {/* Added active, disabled class according to req. */}
                Create
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/* Create New Category Modal */}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CreateNewCSTComponent));
